import React, { Component, useEffect, useState } from "react";
import Link from "next/link";
import styled from "styled-components";
import { Container, Row, Col } from "styled-bootstrap-grid";

import { CATEGORY_IDS } from "../config/constants";

import NewsService from "../services/news";
import MatchService from "../services/matches";
import RankingService from "../services/ranking";
import FeatureFlagsService from "../services/feature-flags";

import Layout from "../components/Shared/Layout";
import Title from "../components/Shared/Title";
import CategoryWidget from "../components/Shared/CategoryWidget";
import Button, { buttonThemes, buttonSizes } from "../components/Shared/Button";
import Meta from "../components/Shared/Meta";
import RankingBox from "../components/Shared/RankingBox";
import RankingValveBox from "../components/Shared/RankingValveBox";
import AdManagerUnit from "../components/Shared/AdManagerUnit";

import NewsList from "../components/News/NewsList";
import FeaturedNews from "../components/News/FeaturedNews";
import HomeRight from "../components/Matches/HomeRight";
import PopularNewsSelector from "../components/News/PopularNewsSelector";
import MatchCarousel from "../components/Matches/MatchCarousel";
import { GCMediaProductsNews } from "../components/News/GCMediaProductsNews";
import LiveOddsService from "../services/live-odds";
import GenericLiveOddsLeaderboard from "../components/LiveOdds/LiveOddsLeaderboard";
import providers from "../components/LiveOdds/providers";
import dayjs from "dayjs";
import GenericLiveOddsLeaderboardNew from "../components/LiveOdds/LiveOddsLeaderboardNew";

export default class Home extends Component {
  static async getInitialProps() {
    const matchesPromise = MatchService.getMatches(1, 100, 0);
    const resultsPromise = MatchService.getMatches(1, 100, 1);
    const columnsPromise = NewsService.getNews({
      page: 1,
      amount: 5,
      categories: CATEGORY_IDS.colunas,
    });

    const sponsoredPromise = NewsService.getNews({
      page: 1,
      amount: 2,
      categories: CATEGORY_IDS.patrocinado,
    });

    const fixedPromise = NewsService.getNews({
      page: 1,
      amount: 1,
      categories: CATEGORY_IDS.fixado,
    });

    const featuredNewsPromise = NewsService.getNews({
      page: 1,
      amount: 5,
      categories: CATEGORY_IDS.destaque,
      playByPlay: true,
    });
    const gcNewsPromise = NewsService.getNews({
      page: 1,
      amount: 5,
      categories: CATEGORY_IDS["gamers-club"],
    });
    const ofertasPromise = NewsService.getNews({
      page: 1,
      amount: 5,
      categories: CATEGORY_IDS.ofertas,
    });

    // const rankingPromise = RankingService.getRankingDetail("latest");

    const rankingValvePromise = RankingService.getRankingByRegion(
      "americas",
      "2024-08-06"
    );

    // const popularNewsPromise = NewsService.getPopularNews(
    //   NewsService.RANGES.MONTH
    // );

    const flagsRes = await FeatureFlagsService.getFeatureFlags();
    const flags = flagsRes.data.data;
    const newsCategoryFlag =
      flags.length === 0
        ? undefined
        : flags.find((flag) => flag.name === "HOME_NEWS_CATEGORIES");
    const newsCategories = !!newsCategoryFlag
      ? newsCategoryFlag.value
      : undefined;

    const newsAmount = 25;
    const newsPromise = NewsService.getNews({
      page: 1,
      amount: newsAmount,
      category: newsCategories,
      playByPlay: true,
    });

    const [
      matchesResponse,
      resultsResponse,
      newsResponse,
      columnsResponse,
      featuredNews,
      // rankingResponse,
      rankingValveResponse,
      gcNewsResponse,
      ofertasResponse,
      // popularNewsResponse,
      sponsoredResponse,
      fixedResponse,
    ] = await Promise.allSettled([
      matchesPromise,
      resultsPromise,
      newsPromise,
      columnsPromise,
      featuredNewsPromise,
      // rankingPromise,
      rankingValvePromise,
      gcNewsPromise,
      ofertasPromise,
      // popularNewsPromise,
      sponsoredPromise,
      fixedPromise,
    ]);
    const matches =
      matchesResponse.status === "fulfilled"
        ? matchesResponse.value.data.data.list
        : [];

    const sponsoredNews =
      sponsoredResponse.status === "fulfilled"
        ? sponsoredResponse.value.data.data.news
        : [];
    const fixedNews =
      fixedResponse.status === "fulfilled"
        ? fixedResponse.value.data.data.news
        : [];

    return {
      news:
        newsResponse.status === "fulfilled"
          ? newsResponse.value.data.data.news
          : [],
      sponsoredNews: [...sponsoredNews, ...fixedNews],
      newsCategories: newsCategories ? newsCategories : [],
      columns:
        columnsResponse.status === "fulfilled"
          ? columnsResponse.value.data.data.news
          : [],
      matches,
      results:
        resultsResponse.status === "fulfilled"
          ? resultsResponse.value.data.data.list
          : [],
      featuredNews:
        featuredNews.status === "fulfilled"
          ? featuredNews.value.data.data.news
          : [],
      // ranking:
      //   rankingResponse.status === "fulfilled"
      //     ? rankingResponse.value.data
      //     : [],
      rankingValve:
        rankingValveResponse.status === "fulfilled"
          ? rankingValveResponse.value.data
          : [],
      gcNews:
        gcNewsResponse.status === "fulfilled"
          ? gcNewsResponse.value.data.data.news
          : [],
      ofertasNews:
        ofertasResponse.status === "fulfilled"
          ? ofertasResponse.value.data.data.news
          : [],
      // popularNews:
      //   popularNewsResponse.status === "fulfilled"
      //     ? popularNewsResponse.value.data.data
      //     : [],
    };
  }

  constructor() {
    super();
    this.state = { odds: [] };
  }

  loadOdds = async () => {
    const odds = await LiveOddsService.getOddsByMatches(this.props.matches);
    this.setState({ odds });
  };

  componentDidMount() {
    this.loadOdds();
  }

  render() {
    const {
      news,
      sponsoredNews,
      newsCategories,
      columns,
      matches,
      results,
      featuredNews,
      // ranking,
      rankingValve,
      gcNews,
      ofertasNews,
      // popularNews,
    } = this.props;

    return (
      <Layout noPadding>
        <Meta canonical="/" />

        {matches.length > 0 && (
          <MatchCarousel data={matches} odds={this.state.odds} />
        )}

        <Container>
          <Row>
            <Col lg={12}>
              <TopContent>
                {/* <AdManagerUnit noMargin name="HOMEPAGE_BILLBOARD" /> */}
                {/* <GenericLiveOddsLeaderboard
                  provider={providers.rivalry}
                  limit={3}
                  random
                /> */}
                <GenericLiveOddsLeaderboardNew
                provider={providers.betboom}
                limit={3}
                reverse
                // noNames
                // random
                // fullWidth
              />
              </TopContent>
            </Col>
          </Row>
        </Container>

        <Container>
          <Row>
            <Col lg={12}>
              <FeaturedNews data={featuredNews} />
            </Col>
          </Row>
        </Container>

        <Container>
          <Row>
            <Col lg={12}>
              <TopContent>
                <AdManagerUnit noMargin name="HOMEPAGE_SECONDARY_BILLBOARD" />
                {/* <AdManagerUnit noMargin minHeight={90} name="HOMEPAGE_SECONDARY_BILLBOARD" /> */}
              </TopContent>
            </Col>
          </Row>
        </Container>

        <Container>
          <HomeRow>
            <HomeColumn lg={4} hideOnMobile>
              {/* <AdManagerUnit noMargin name="HOMEPAGE_SQUARE_LEFT_TOP" /> */}
              {/* <br />
              <Row>
                <Col xs={6}>
                  <Title text="Notícias populares" />
                  <PopularNewsSelector initialData={popularNews || []} />
                </Col>
              </Row>
              <br />
              <Row>
                <Col xs={6}>
                  <Title text="GC MEDIA" />
                  <GCMediaProductsNews />
                </Col>
              </Row> */}
              {/* <br />
              <AdManagerUnit name="HOMEPAGE_SQUARE_LEFT" /> */}
              <Row>
                {/* <Col xs={6}>
                  <Title
                    text={`Ranking Valve ${dayjs(
                      rankingValve.data[0].rankingValveEditionDate
                    ).format("DD/MM")}`}
                  />
                  <RankingValveBox data={rankingValve} />
                </Col> */}

                {/* <Col xs={6}>
                  <Title text="Ranking" />
                  <RankingBox data={ranking} />
                </Col> */}

                {/* <br /> */}
                <AdManagerUnit noMargin name="HOMEPAGE_SQUARE_MIDDLE" />
                {/* <Col xs={6}>
                  <CategoryWidget
                    linkTo="colunas"
                    data={columns}
                    title="Colunas"
                    href="/colunas/:columnName/:columnSlug"
                  />
                </Col> */}
                <Col xs={6}>
                  <CategoryWidget
                    linkTo="ofertas"
                    data={ofertasNews}
                    title="Ofertas 🔥"
                    titleButton="Ver todas"
                    hideAuthorImage
                    showPostImage
                    hideCategory
                    isNews
                    href="/noticia/[slug]"
                  />
                </Col>
              </Row>

              <br />
              <AdManagerUnit name="HOMEPAGE_SQUARE_LEFT_BOTTOM" />

              <Row>
                <Col xs={6}>
                  <CategoryWidget
                    linkTo="gamersclub"
                    data={gcNews}
                    title="Gamers Club"
                    titleButton="Ver todas"
                    hideAuthorImage
                    hideCategory
                    isNews
                    href="/noticia/[slug]"
                  />
                </Col>
              </Row>
            </HomeColumn>
            <HomeColumn lg={4}>
              <Title text="Últimas Notícias" margin />
              <NewsList
                initialData={news}
                sponsored={sponsoredNews}
                category={newsCategories}
              />
              <Link prefetch={false} href="/arquivo" passHref>
                <Button
                  theme={buttonThemes.default.ACCENT}
                  text="Arquivo"
                  size={buttonSizes.MEDIUM}
                />
              </Link>
            </HomeColumn>
            <HomeColumn lg={4}>
              <HomeRight
                matches={matches.slice(0, 8)}
                results={results.slice(0, 8)}
              />
            </HomeColumn>
          </HomeRow>
        </Container>
      </Layout>
    );
  }
}

const HomeRow = styled.div`
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 990px) {
    flex-direction: column;
  }
`;

const HideOnDesktop = styled.div`
  display: none;

  @media screen and (max-width: 768px) {
    display: block;
  }
`;

const HideOnMobile = styled.div`
  display: block;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const HomeColumn = styled.div`
  @media screen and (max-width: 768px) {
    ${(props) => (props.hideOnMobile ? "display: none" : "")}
  }

  &:nth-child(2) {
    flex: 1;
    margin: 0 24px;

    @media screen and (max-width: 990px) {
      margin: 0;
    }
  }

  &:first-child,
  &:last-child {
    flex-basis: 300px;
  }
`;

const TopContent = styled.div`
  margin: 16px 0;
`;

