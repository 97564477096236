import React, { Component, Fragment } from "react";
import styled from "styled-components";

// 3rd party components
import Link from "next/link";

// components
import Card from "./Card";
import Title from "./Title";
import Button, { buttonThemes, buttonSizes } from "./Button";

// css
import EmptyState from "./EmptyState";
import RouteGenerator from "../../util/route-generator";
import { colors } from "../../config/styles";
import slugify from "slugify";

class CategoryWidget extends Component {
  render() {
    const {
      linkTo,
      data,
      title,
      titleButton,
      hideCategory,
      hideAuthorImage,
      showPostImage,
      hideButton,
      isNews,
      href,
    } = this.props;

    return (
      <div>
        {data && data.length > 0 && <Title text={title} margin />}

        {data &&
          data.length > 0 &&
          data.map((column) => (
            <Link
              prefetch={false}
              key={`CategoryWidget_Column_${column.postSlug}`}
              as={
                isNews
                  ? RouteGenerator.getNewsRoute(column.postSlug)
                  : RouteGenerator.getColumnRoute(
                      slugify(column.postCategory[1] || "colunas"),
                      column.postSlug
                    )
              }
              href={href}
              passHref
            >
              <ColumnLink>
                <ColumnCard key={column.postId} noPadding>
                  {!hideAuthorImage && (
                    <AuthorImage
                      src={`https://www.gravatar.com/avatar/${column.author.emailHash}`}
                      alt={`${column.author.name}`}
                    />
                  )}
                  {!!showPostImage && <ColumnImage src={column.postImage} />}
                  
                  <ColumnText single={hideCategory}>
                    {!hideCategory && (
                      <ColumnCategory>
                        {column.postCategory[1] ||
                          column.author.name ||
                          "Coluna"}
                      </ColumnCategory>
                    )}
                    <ColumnTitle>{column.postTitle}</ColumnTitle>
                  </ColumnText>
                </ColumnCard>
              </ColumnLink>
            </Link>
          ))}

        {data && data.length > 0 && !hideButton && (
          <Link prefetch={false} href={`/${linkTo}`} passHref>
            <Button
              theme={buttonThemes.default.ACCENT}
              size={buttonSizes.SMALL}
              text={titleButton}
            />
          </Link>
        )}
      </div>
    );
  }
}

const ColumnCard = styled(Card)`
  margin: 8px 0;
  /* min-height: 60px; */
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Roboto", sans-serif;
`;

const AuthorImage = styled.img`
  border-radius: 50%;
  border: 1px solid #fff;
  width: 36px;
  height: 36px;
  margin: 0px 8px;
`;

const ColumnImage = styled.img`
  border-radius: 50%;
  border: 1px solid #fff;
  width: 36px;
  height: 36px;
  margin: 0px 8px;
`;

const ColumnText = styled.div`
  flex: 1;
  /* border: 1px solid red; */

  padding: ${(props) => (props.single ? "8px" : "0 8px 8px 8px")};
`;

const ColumnCategory = styled.span`
  width: 100%;
  font-size: 10px;
  color: #959595;
  display: inline-block;
  margin-top: 8px;
  margin-bottom: 2px;
  text-transform: uppercase;
  font-weight: 400;
  line-height: 18px;

  @media screen and (max-width: 768px) {
    font-size: 16px;
    /* font-weight: bold; */
  }
`;

const ColumnTitle = styled.span`
  font-size: 13px;
  display: inline-block;
  font-weight: 700;

  @media screen and (max-width: 768px) {
    font-size: 20px;
    line-height: 1.5;
  }
`;

const ColumnLink = styled.a`
  color: ${colors.GRAY_DARK};
`;

export default CategoryWidget;
