import React, { Fragment, useContext } from "react";
import styled from "styled-components";
import { Row, Col } from "styled-bootstrap-grid";
import { S3_BASE_URL } from "../../config/constants";
import { colors } from "../../config/styles";
import dayjs from "dayjs";
import Link from "next/link";
import RouteGenerator from "../../util/route-generator";
import FeaturedHomeStream from "../Shared/FeaturedHomeStream";
import { FeatureFlagContext } from "../../contexts/FeatureFlagContext";

const FeaturedNews = (props) => {
  const context = useContext(FeatureFlagContext);

  const flagValueTwitch = context.getFlagValue("FEATURED_STREAM");
  const flagValueYoutube = context.getFlagValue("FEATURED_STREAM_YOUTUBE");

  const renderDefaultNews = (news, isSecondary) => (
    <Link
      prefetch={false}
      href={
        news.postCategorySlug
          ? `/colunas/${news.postCategorySlug}/[slug]`
          : "/noticia/[slug]"
      }
      passHref
      as={
        news.postCategorySlug
          ? RouteGenerator.getNewsRoute(news.postCategorySlug, news.postSlug)
          : RouteGenerator.getNewsRoute(news.postSlug)
      }
    >
      <Main background={news.postImage} secondary={isSecondary}>
        <TitleContainer secondary={isSecondary}>
          <CategoryContainer>
            {news.postCategory?.map((category) => (
              <label>{category}</label>
            ))}
          </CategoryContainer>
          {isSecondary ? <h4>{news.postTitle}</h4> : <h3>{news.postTitle}</h3>}
          {!isSecondary && <p>{news.postExcerpt}</p>}
          <PostMeta>
            <span>
              Por <strong>{news.author.name}</strong>
            </span>

            <span>{dayjs(news.postDate).format("DD [de] MMM")}</span>
          </PostMeta>
        </TitleContainer>
      </Main>
    </Link>
  );

  const getCardBackgroundImage = (data) => {
    // console.log("data PBP:", data);
    if (data.postImage) {
      return `${data.postImage}`;
    }

    if (
      !data.postImage &&
      data.tournament.tournamentImage &&
      data.tournament.tournamentImage !== "undefined"
    ) {
      // noticia destaques
      return `${S3_BASE_URL}/${data.tournament.tournamentImage}`;
    }

    return `${S3_BASE_URL}/assets/IMAGEM-PBP.png`;
  };

  const renderPlayByPlayNews = (news, isSecondary) => (
    <Link
      prefetch={false}
      href="/partida/[id]"
      passHref
      as={RouteGenerator.getMatchRoute(news)}
    >
      <Main background={getCardBackgroundImage(news)} secondary={isSecondary}>
        <TitleContainer secondary={isSecondary}>
          <label>{news.isOver ? "Pós Jogo" : "Tempo real"}</label>
          {isSecondary ? (
            <h4>
              {!!news.isOver && <span>{news.postTitle}</span>}

              {!news.isOver && (
                <span>
                  Acompanhe ao vivo {news.teamA.teamName} vs{" "}
                  {news.teamB.teamName} na {news.tournament.tournamentName}
                </span>
              )}
            </h4>
          ) : (
            <h3>
              {!!news.isOver && <span>{news.postTitle}</span>}

              {!news.isOver && (
                <span>
                  Acompanhe ao vivo {news.teamA.teamName} vs{" "}
                  {news.teamB.teamName} na {news.tournament.tournamentName}
                </span>
              )}
            </h3>
          )}

          <PostMeta>
            <span>Lance a lance</span>

            <span>{dayjs(news.postDate).format("DD [de] MMM")}</span>
          </PostMeta>
        </TitleContainer>
      </Main>
    </Link>
  );

  return (
    <FeaturedNewsContainer>
      <Row>
        <Col lg={8}>
          <Fragment>
            <FeaturedHomeStream />
          </Fragment>

          {props.data[0] && !flagValueTwitch && !flagValueYoutube && (
            <Fragment>
              {props.data[0].type === "news"
                ? renderDefaultNews(props.data[0], false)
                : renderPlayByPlayNews(props.data[0], false)}
            </Fragment>
          )}
        </Col>
        <Col lg={4}>
          <SecondaryContainer>
            {props.data[1] && (
              <Fragment>
                {props.data[1].type === "news"
                  ? renderDefaultNews(props.data[1], true)
                  : renderPlayByPlayNews(props.data[1], true)}
              </Fragment>
            )}

            {props.data[2] && (
              <Fragment>
                {props.data[2].type === "news"
                  ? renderDefaultNews(props.data[2], true)
                  : renderPlayByPlayNews(props.data[2], true)}
              </Fragment>
            )}
          </SecondaryContainer>
        </Col>
      </Row>
    </FeaturedNewsContainer>
  );
};

const FeaturedNewsContainer = styled.div`
  // margin-bottom: 24px;
`;

const CategoryContainer = styled.div`
  display: flex;
  gap: 6px 8px;
  flex-wrap: wrap;
  margin-bottom: 8px;
`;

const Main = styled.a`
  height: ${(props) => (props.secondary ? "215px" : "450px")};
  width: 100%;
  display: block;
  background-image: ${(props) =>
    props.background
      ? `url(${props.background})`
      : "url(https://static.draft5.gg/assets/IMAGEM-PBP.png)"};
  background-size: cover;
  background-position: center;
  background-color: ${colors.MAIN};
  border-radius: 2px;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.1s;
  text-decoration: none;
`;

const TitleContainer = styled.div`
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.1) 20%,
    rgba(0, 0, 0, 1) 100%
  );
  height: 100%;
  width: 100%;
  padding: ${(props) => (props.secondary ? "15px" : "20px")};
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding-bottom: 15px;
  transition: all 0.3s linear;
  z-index: 1;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.3) 30%,
      rgba(0, 0, 0, 1) 100%
    );
    z-index: -1;
    transition: opacity 0.3s linear;
    opacity: 0;
  }

  &:hover {
    padding-bottom: 20px;

    &:before {
      opacity: 1;
    }
  }

  label {
    background: ${colors.ACCENT};
    padding: ${(props) => (props.secondary ? "5px" : "10px")};
    color: white;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: bold;
    border-radius: 2px;
    /* margin-bottom: 12px; */

    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  h3,
  h4 {
    color: white;
    font-weight: normal;
    margin: 0;
    font-weight: bold;
    bottom: ${(props) => (props.secondary ? "20px" : "30px")};
    font-size: 30px;

    @media screen and (max-width: 768px) {
      font-size: 45px;
    }
  }

  p {
    color: white;
    margin: 8px 0;
    color: ${colors.GRAY_LIGHTER};
    font-size: ${(props) => (props.secondary ? "14px" : "inherit")};

    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  h4 {
    font-size: 16px;
    line-height: 1.5;

    @media screen and (max-width: 768px) {
      font-size: 24px;
    }
  }
`;

const SecondaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  @media screen and (max-width: 992px) {
    margin-top: 12px;
    flex-direction: row;

    & a:first-child {
      margin-right: 8px;
    }
  }
`;

const PostMeta = styled.div`
  border-top: 1px solid ${colors.GRAY};
  width: 100%;
  padding: 12px 0;
  margin-top: 12px;
  color: ${colors.GRAY_LIGHT};
  font-size: 12px;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 768px) {
    /* display: none; */
    font-size: 20px;
    border: 0px;
    padding: 4px 0;
  }
`;

export default FeaturedNews;
